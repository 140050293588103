:root {
    --elo-width: calc(var(--width) * 0.2);
    --name-width: calc(var(--width) * 0.3);
    --status-width: calc(var(--width) * 0.25);
    --challenge-button-width: calc(var(--width) * 0.25);
    --line-height: calc(var(--width) * 0.09);
}

.lobby-main-cont {
    position: absolute;
    top: 0,
}

.lobby-main-cont button {
    height: calc(var(--width) * 0.05)
}

.challenge-main-cont {
    width: var(--width);
    height: var(--height);
    position: absolute;
    display: grid;
    justify-content: center;
    align-content: center;
    transition: opacity 0.25s;
    opacity: 0;
}

.challenge-cont {
    width: calc(var(--width) * 0.8);
    max-width: 500px;
    height: calc(var(--width) * 0.2);
    background: rgb(255, 157, 157);
    display: grid;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.challenge-buttons {
    margin-top: calc(var(--width) * 0.03);
    display: grid;
    justify-content: center;
    align-content: center;
}

.challenge-buttons-victim {
    margin-top: calc(var(--width) * 0.03);
    display: flex;
}

.lobby-tab-cont {
    width: var(--width);
}

.lobby-tab-header {
    display: flex;
    text-align: center;
}

.header-elo {
    width: var(--elo-width);
    outline: 1px solid red;
}

.header-name {
    width: var(--name-width);
    outline: 1px solid red;
}

.header-status {
    width: var(--status-width);
}

.header-challenge {
    width: var(--challenge-button-width);
}

.lobby-user-line {
    display: flex;
    height: var(--line-height);
    line-height: var(--line-height);
    text-align: center;
}

.lobby-line-name {
    text-align: left;
    width: var(--name-width);
    display: flex;
}

.lobby-line-elo {
    width: var(--elo-width);
}

.lobby-line-status {
    width: var(--status-width);
}

.lobby-line-button {
    width: var(--challenge-button-width);
}

.lobby-title-cont {
    text-align: center;
    text-transform: uppercase;
    font-family: impact;
    color: #1b1b1b;
    margin-top: calc(var(--width) * 0.01);
    font-size: calc(var(--fontSize) * 1.5);
}

.lobby-playbot-cont {
    margin-top: calc(var(--width) * 0.05);
    margin-bottom: calc(var(--width) * 0.05);
}

.langage {
    margin-top: calc(var(--width) * 0.025);
    
    width: var(--width);
    display: flex;
    justify-content: center;
}

.langage-center {
    width: calc(var(--width) * 0.3);
    height: calc(var(--width) * 0.08);
    display: flex;
    justify-content: space-evenly;
}

.langage-flag {
    width: calc(var(--width) * 0.14);
    height: 100%;
    cursor : pointer;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}