:root {
    --board-width: var(--width);
    --board-size: var(--board-width);
    --board-case-size: calc(var(--board-size) / 8);
    --top-min-height: calc(var(--width) * 0.08);
    --info-cont-height: calc(var(--width) * 0.15);
    --button-zone-heigth: calc(var(--width) * 0.14);
    --points-cont-height: calc(var(--width) * 0.08);
    --gold-cont-height: calc(var(--width) * 0.06);
    --top-base-height: calc(var(--height) - (var(--board-size) + var(--info-cont-height) + var(--button-zone-heigth) + var(--points-cont-height) + var(--gold-cont-height)));
    --top-height: var(--top-base-height);
    --top-power-height: calc(var(--top-base-height) - var(--top-min-height));
    --power-card-width: calc(var(--width) * 0.95);
    --power-card-height: calc(var(--width) * 0.2);
    --top-power-cirlce-size: min(calc(var(--top-power-height) * 0.8), calc(var(--width) * 0.14))
}

.blur {
    filter: blur(calc(var(--width) * 0.01));
}

.bottom-zone {
    height: calc(var(--height) - (var(--board-size) + var(--top-height) + var(--info-cont-height) + var(--button-zone-heigth) + var(--points-cont-height) + var(--gold-cont-height)));
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.top-power-cont {
    height: var(--top-power-height);
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.top-power-flex {
    height: var(--top-power-cirlce-size);
    display: flex;
    justify-content: space-between;
}

.top-powers {
    display: flex;
}

.over-power-cont {
    width: var(--width);
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 3;
}

.power-circle {
    width: calc(var(--top-power-cirlce-size) * 0.9);
    height: calc(var(--top-power-cirlce-size) * 0.9);
    border-radius: calc(var(--top-power-cirlce-size) * 0.9 * 0.5);
    box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.77);
    margin-left: calc(var(--width) * 0.015);
    margin-right: calc(var(--width) * 0.015);
    background-size: contain;
}

.bottom-zone::-webkit-scrollbar {
    display: none;
}

.buttons-zone {
    height: var(--button-zone-heigth);
    transition: opacity 0.1s;
}

.button-cont {
    height: calc(var(--button-zone-heigth) * 0.9);
}

.game-main-cont {
    user-select: none;
    width: var(--width);
    height: var(--height);
    position: absolute;
    transition: opacity 0.35s;
    background-size: cover;
    overflow: hidden;
}

.points-cont {
    width: calc(var(--width));
    height: var(--points-cont-height);
    text-align: center;
    background: #5e8c65;
    font-family: lcd;
    font-size: calc(var(--fontSize) * 1.5);
    text-transform: uppercase;
    box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.77);
    display: flex;
    justify-content: space-between;
    line-height: var(--points-cont-height);
}

.point-value {
    margin-left: calc(var(--points-cont-height) * 0.25);
    margin-right: calc(var(--points-cont-height) * 0.25);
}

.gold-cont {
    width: var(--width);
    height: var(--gold-cont-height);
}

.gold-value-cont {
    width: var(--width);
    height: var(--gold-cont-height);
    line-height: var(--gold-cont-height);
    display: flex;
    justify-content: space-between;
}

.gold-bar-cont {
    width: 100%;
    height: var(--gold-cont-height);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.gold-bar-cont-little {
    height: calc(var(--gold-cont-height) * 0.1);
    margin-top: calc(var(--gold-cont-height) * 0.3);
    display: flex;
    justify-content: space-between;
}

.gold-bar {
    background: #ffe587;
    height: 100%;
    transition: width 1s;
}

.gold-bar-player1 {
    border-radius: 0 5px 5px 0;
}

.gold-bar-player2 {
    border-radius: 5px 0 0 5px;
}

.gold-value-flex {
    display: flex;
}

.gold-value {
    color: #ffe587;
    text-align: center;
    width: calc(var(--width) * 0.08)
}

.gold-value-symb {
    width: calc(var(--width) * 0.06);
    height: 100%;
    background-image: url("../assets/gold.png");
    background-size: contain;
}


.board-flex {
    display: flex;
    /* justify-content: center; */
}

.game-top-cont {
    height: var(--top-height);
    transition: height 0.35s, width 0.35s;
}

.game-top-buttons {
    /* border-bottom: 1px solid grey; */
    height: var(--top-min-height);
    line-height: var(--top-min-height);
    display: flex;
    justify-content: space-between;
}

.game-top-button-player {
    width: calc(var(--width) * 0.33);
    color: white;
}

.game-top-button-player1 {
    margin-left: calc(var(--width) * 0.02);
    text-align: left;
}

.game-top-button-player2 {
    margin-right: calc(var(--width) * 0.02);
    text-align: right;
}

.game-top-button-option {
    width: calc(var(--width) * 0.32);
}

.side-power {
    width: calc((var(--width) - var(--board-size)) * 0.5);
    display: flex;
    justify-content: center;
    z-index: 1;
}

.board-cont-grid {
    width: var(--width);
    height: var(--board-size);
    transition: height 0.35s, width 0.35s;
}

.board-cont {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: var(--board-size);
    height: var(--board-size);
    transition: height 0.35s, width 0.35s;
    /* box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.77); */
    box-shadow: 0px 4px 4px -1px rgba(0, 0, 0, 0.77);
    z-index: 2;
}

.board-line {
    width: var(--board-size);
    display: flex;
    transition: height 0.35s, width 0.35s;
}

.board-case {
    width: var(--board-case-size);
    height: var(--board-case-size);
    outline: 1px solid rgba(129, 78, 24, 0.196);
    transition: height 0.35s, width 0.35s;
    color: white;
}

.case-piece-cont {
    position: absolute;
    width: var(--board-case-size);
    height: var(--board-case-size);
    transition: height 0.35s, width 0.35s;
    z-index: 2;
}

.case-piece {
    /* margin-top: calc(var(--board-case-size) * 0.15); */
    width: calc(var(--board-case-size) * 0.6);
    height: calc(var(--board-case-size) * 0.6);
    border-radius: calc(var(--board-case-size) * 0.6 * 0.5);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 102%;
    transition: height 0.35s, width 0.35s;
}

.board-case-background-effect {
    position: absolute;
    width: var(--board-case-size);
    height: var(--board-case-size);
    z-index: 0;
    mix-blend-mode: multiply;
    transition: height 0.35s, width 0.35s, background 0.35s;
}

.board-case-line-paint-cont {
    position: absolute;
    width: calc(var(--board-case-size));
    height: var(--board-case-size);
    /* height: calc(var(--board-case-size) * 0.2); */
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
}

.board-case-line-paint-middle {
    width: calc(var(--board-case-size));
    height: calc(var(--board-case-size) * 0.2);
    /* margin-top: calc(var(--board-case-size) * 0.15); */
}

.board-case-line {
    position: absolute;
    width: calc(var(--board-case-size));
    border-radius: calc(var(--board-case-size) * 0.2 * 0.5);

    height: calc(var(--board-case-size) * 0.2);
    /* background: #880000; */
}

.case-point {
    position: absolute;
    margin-left: calc(var(--board-case-size) * 0.03);
    margin-top: calc(var(--board-case-size) * 0.03);
    color: rgb(217, 183, 151);
    font-size: calc(var(--board-case-size) * 0.25);
}

.case-forbid {
    position: absolute;
    width: calc(var(--board-case-size) * 0.33);
    height: calc(var(--board-case-size) * 0.33);
    margin-left: calc(var(--board-case-size) - (var(--board-case-size) * 0.33));
    background-image: url(../assets/forbid.png);
    background-size: contain;
    background-repeat: no-repeat;
    transition: height 0.35s, width 0.35s, background 0.35s;
    opacity: 0.5;
}

.case-target {
    position: absolute;
    width: var(--board-case-size);
    height: var(--board-case-size);
    background: red;
}

.infos-cont {
    width: calc(var(--width));
    height: var(--info-cont-height);
    /* padding: calc(var(--width) * 0.01); */
    /* border : 1px solid black; */
    text-align: center;
    background: #5e8c65;
    font-family: lcd;
    font-size: calc(var(--fontSize) * 1.5);
    text-transform: uppercase;
    box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.77);
}

.info-line {
    height: calc(var(--info-cont-height)* 0.32);
}

.power-cont {
    width: var(--width);
    height: var(--power-card-height);
    display: flex;
}

.power-content {
    width: var(--power-card-width);
    border-radius: calc(var(--power-card-width) * 0.015);
    height: calc(var(--power-card-height) * 0.9);
    background: #0c305b;
    color: white;
    display: flex;
    box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.77);
}

.power-infos-desc-flex {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: calc(var(--power-card-height) * 0.5);
}

.power-infos-content {
    margin-left: calc(var(--power-card-width) * 0.018);
    width: calc(var(--power-card-width) * 0.8);
}

.power-infos-description {
    font-size: calc(var(--fontSize) * 0.8);
}

.power-infos-content-header {
    margin-top: calc(var(--power-card-width) * 0.01);
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}

.power-infos-gold {
    margin-right: calc(var(--power-card-width) * 0.02);
    color: #ffe587;
}

.power-picture {
    width: calc(var(--width) * 0.2);
}

.power-picture-content {
    width: calc(var(--width) * 0.18);
    height: calc(var(--power-card-height) * 0.75);
    border-radius: calc(var(--power-card-width) * 0.01);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.pick-buttons {
    display: flex;
}

.pick-knock {
    display: flex;
    justify-content: center;
}

.endGame-cont {
    position: absolute;
    width: var(--width);
    height: calc(var(--width) * 0.2);
    text-align: center;
}

.endGame-popup {
    background: white;
    width: calc(var(--width) * 0.4);
    height: calc(var(--width) * 0.2);
    box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.77);
    border-radius: calc(var(--width) * 0.02);
}

.endGame-result {
    font-weight: bold;
}