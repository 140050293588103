:root {
  --allWidth: 0px;
  --allHeight: 0px;
  
  --width: 0px;
  --height: 0px;
  --fontSize: calc(var(--width) * 0.035);
  font-size: var(--fontSize);
}

.grid {
  display: grid;
  justify-content: center;
  align-items: center;
}

.all {
  width: var(--allWidth);
  height: var(--allHeight);
  display: grid;
  justify-content: center;
  align-items: center;
  background : #dadada;
}

.view {
  width: var(--width);
  height: var(--height);
  overflow: hidden;
  background : white;
}

* { 
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}