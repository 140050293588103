:root {
    --login-field-width: min(500px, calc(var(--width) * 0.9));
    /* --login-field-height: calc(var(--login-field-width) * 0.5); */
}

.login-main-cont {
    width: var(--width);
    height: var(--height);
    display: grid;
    justify-content: center;
    align-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    animation-fill-mode:forwards;
    animation-iteration-count: 1;
    position : absolute;
}

.login-field-cont {
    width: var(--login-field-width);
    height: var(--login-field-height);
    /* background : red; */
    display: grid;
    justify-content: center;
    align-content: center;
}

.login-input {
    /* background: #ffffff11; */
    width: var(--login-field-width);
}

.login-input>div {
    background: #ffffffee;
    /* width: calc(var(--login-field-width) * 0.7); */
}

.login-field-div {
    margin-top: calc(var(--width) * 0.02);
}

.login-button-cont {
    margin-top: calc(var(--width) * 0.02);
    display: flex;
    justify-content: space-between;
}

.login-button {
    width: calc(var(--login-field-width) * 0.48);
}
